* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: none;
  word-break: break-word;
}

html,
body {
  color: $theme-text;
  font-family: $primary-font;
  /* stylelint-disable */
  font-size: 16px;
  /* stylelint-enable */
  scroll-behavior: smooth;
}

body {
  background-color: $dark-grey-2;
  font-size: rem(17);
}

ul,
ol {
  padding: 0;
  margin: 0;

  li {
    font-weight: 400;
    list-style: none;
  }
}

p {
  font-size: rem(17);
  font-weight: 300;
  letter-spacing: rem(-0.51);
  line-height: 135%;
  
  &:has(+p) {
    margin: 0 0 rem(20);
  }

  &:has(+ul, +ol) {
    margin-bottom: rem(23);
  }

  &:empty {
    display: none;
  }
}


a {
  color: $theme-text;
  font-weight: 400;
  text-decoration: none;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

a,button{
  cursor: default;

  &.is-clickable{
    cursor: pointer;
  }
}