.is-hidden {
	display: none;
}

.l-cardlink {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	font-size: 0;
}

.l-title {
	color: $light-grey;
	font-size: fluid(32, 42);
	font-weight: 400;
	letter-spacing: rem(-0.32);
	line-height: 100%;
	text-transform: uppercase;

	@media #{$lg} {
		letter-spacing: rem(-0.42);
	}
}

.l-btn {
	position: relative;
	z-index: 1;
	display: inline-block;
	overflow: hidden;
	padding: rem(8) rem(16) rem(9);
	border: rem(1) solid $grey;
	color: $white;
	font-size: rem(12);
	font-weight: 500;
	line-height: 100%;
	text-transform: uppercase;

	@include transition;

	&::before {
		@media #{$lg} {
			position: absolute;
			z-index: -1;
			top: 0;
			left: -100%;
			width: 100%;
			height: 100%;
			background-color: $white;
			content: '';
			
			@include transition;
		}
	}

	&::after{
		@include transition;
	}

	&:hover{
		border-color: $white;
		background-color: $white;
		color: $black;

		@media #{$lg} {
			background-color: unset;
		}

		&::before {
			left: 0;
		}

		&::after{
			color: $black;
		}
	}

	&--icon {
		display: inline-flex;
		min-width: rem(220);
		align-items: center;
		justify-content: space-between;

		&::after {
			padding-left: rem(8);
			color: $green;
			content: '→';
		}
	}

	&--icon-plus {
		&::after {
			content: '+';
		}
	}

	&--green {
		border: rem(1) solid $green;
		background-color: $green;
		color: $grey-900;

		&::before {
			background-color: $grey-900;
		}

		&::after {
			color: $grey-900;
		}

		&:hover{
			border-color: $green;
			background-color: transparent;
			color: $green;

			@media #{$lg} {
				background-color: $green;
			}

			&::after{
				color: $green;
			}
		}
	}

	&--white {
		padding-top: rem(9);
		padding-bottom: rem(10);
		border: none;
		background-color: $white;
		color: $grey-900;

		&::before {
			background-color: $grey-900;
		}

		&::after {
			color: $grey-900;
		}

		&:hover {
			background-color: $black;
			color: $white;

			@media #{$lg} {
				background-color: $white;
			}
		}
	}

	&--black {
		padding-top: rem(9);
		padding-bottom: rem(10);
		border: none;
		background-color: $grey-800;
		color: $white;

		&::after {
			color: $white;
		}

		&:hover {
			@media #{$lg} {
				background-color: $black;
			}
		}

	}

	&--black-green {
		border: 0;
		background-color: $grey-800;
		color: $green;

		&::after {
			color: $green;
		}

		&:hover {
			@media #{$lg} {
				background-color: $grey-800;
			}
		}

	}

	&--xl {
		min-width: rem(240);
		padding: rem(13) rem(19) rem(14) rem(15);
	}
}

.l-tag {
	max-width: fit-content;
	padding: rem(6) rem(8);
	border-radius: rem(3);
	background-color: $green;
	color: $grey-900;
	font-size: rem(12);
	font-weight: 500;
	line-height: 100%;
	text-transform: uppercase;

	&--xl {
		padding: rem(8) rem(16);
	}

	&--black {
		background-color: $grey-900;
		color: $green;
	}
}

.aspect-box {
	position: relative;
	display: block;
	width: 100%;

	img,
	video,
	canvas,
	iframe,
	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&::before {
		display: block;
		padding-top: calc(var(--aspect-ratio) * 100%);
		content: "";
	}
}

body [data-aos="fade-up"] {
    transform: translate3d(0, rem(30), 0);
}