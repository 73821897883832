/* Font */
$primary-font: "GT America Mono", sans-serif;

/* Base palette */
$black: #000 !default;
$white: #fff !default;
$grey-100: #DCDCDC !default;
$grey-100-03: rgb(220 220 220 / 30%) !default;
$grey-200: #A6A2A2 !default;
$grey-300: #8F9091 !default;
$grey-400: #4C515C !default;
$grey-600: #242830 !default;
$grey-800: #16171A !default;
$grey-900: #1C1D21 !default;
$grey-950: #0F1012 !default;
$green: #00FFAE !default;
$blue-100: #D2E8E1 !default;
$red: #FF9E9E !default;
$yellow: #ffba08 !default;
$red-100: #FF6B6B !default;

/* Brand palette */
$light-grey: $grey-100;
$mid-grey: $grey-400;
$grey: $grey-300;
$dark-grey: $grey-600;
$dark-grey-2: $grey-800;

/* Theme */
$theme-text: $light-grey;

/* State palette */
$primary-color: $light-grey !default;