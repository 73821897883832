$font-path: './fonts';

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: bold;
    src: url('#{$font-path}/GTAmericaMono-BoldItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-BoldItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 300;
    src: url('#{$font-path}/GTAmericaMono-LightItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-LightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 300;
    src: url('#{$font-path}/GTAmericaMono-Light.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Light.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 500;
    src: url('#{$font-path}/GTAmericaMono-MediumItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-MediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 900;
    src: url('#{$font-path}/GTAmericaMono-BlackItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-BlackItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 500;
    src: url('#{$font-path}/GTAmericaMono-Medium.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono Ultra';
    font-style: italic;
    font-weight: 200;
    src: url('#{$font-path}/GTAmericaMono-UltraLightItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-UltraLightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: normal;
    src: url('#{$font-path}/GTAmericaMono-Regular.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono Ultra';
    font-style: normal;
    font-weight: 200;
    src: url('#{$font-path}/GTAmericaMono-UltraLight.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-UltraLight.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: bold;
    src: url('#{$font-path}/GTAmericaMono-Bold.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: normal;
    src: url('#{$font-path}/GTAmericaMono-RegularItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-RegularItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 900;
    src: url('#{$font-path}/GTAmericaMono-Black.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Black.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: italic;
    font-weight: 100;
    src: url('#{$font-path}/GTAmericaMono-ThinItalic.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-ThinItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT America Mono';
    font-style: normal;
    font-weight: 100;
    src: url('#{$font-path}/GTAmericaMono-Thin.woff2') format('woff2'),
        url('#{$font-path}/GTAmericaMono-Thin.woff') format('woff');
}

