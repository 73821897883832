header.qpac.header {
  position: relative;
  padding-bottom: rem(163);

  @include transition;

  @media #{$lg} {
    padding-bottom: rem(166);
  }

  .header__container {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $grey-800;
    transform: translateY(0);

    @include transition;

    &:has(.header__wrapper.hidden) {
      transform: translateY(-100%);
    }
  }

  .header__wrapper {
    display: flex;
    max-width: rem(1920);
    align-items: center;
    justify-content: space-between;
    padding: rem(22) rem(16);
    margin: 0 auto;
      
    @media #{$lg} {
      padding: rem(16) rem(20);
    }
  }

  .header__logo {   
    width: fluid(131, 161); 

    &.aspect-box {
      --aspect-ratio: 26 / 131;
      
      @media #{$lg} {
        --aspect-ratio: 32 / 161.231;
      }      
    }

    @media #{$lg} {
      min-width: fit-content;
    }
  }

  .header__navlist {
    @include transition;

    z-index: 12;
    display: none;
    align-items: center;
    margin-right: fluid(32, 65, 992);
    gap: fluid(10, 28, 992);

    @media #{$lg-m} {
      position: absolute;
      top: rem(68);
      left: 0;
      width: 100%;
      height: calc(100dvh - rem(68));
      padding: rem(41) rem(20);
      margin: 0;
      opacity: 0;
      transform: translateY(rem(10));

      @include transition;
    }

    @media #{$lg} {
      display: flex;
    }

    li {
      padding: rem(16) 0 rem(15);
      border-top: rem(1) solid $mid-grey;
      font-size: rem(20);
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;

      @media #{$lg} {
        min-width: fit-content;
        padding: 0;
        border: 0;
        font-size: rem(12);
        font-weight: 500;
        line-height: 100%;
      }

      &:hover {
        &>a {
          color: $grey;

          @media #{$lg} {
            color: $mid-grey;
          }
        }
      }

      &.header__quips-login {
        padding-top: rem(32);
        font-size: rem(12);
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-transform: uppercase;

        @media #{$lg} {
          display: none;
        }
      }
    }


    a, span {
      @include transition(color);

      font-weight: 400;

      @media #{$lg-m} {
        display: flex;
        justify-content: space-between;
        color: $white;
      }

      @media #{$lg} {
        display: inline-block;
        padding: rem(13) 0;
        font-weight: 500;
      }

      &:hover {
        color: $grey;

        @media #{$lg} {
          color: $mid-grey;
        }
      }
    }
  }

  .header__subnav {
    display: none;
    width: max-content;
    padding-top: rem(18);

    @media #{$lg} {
      position: absolute;
      left: rem(-16);
      padding-top: 0;
    }

    ul,
    ol {
      padding: rem(16);
      background-color: $dark-grey;
      counter-reset: section;
    }

    li {
      padding: 0;
      border: 0;
      margin-bottom: rem(16);

      @media #{$lg-m} {
        margin-bottom: rem(18);
        font-size: rem(14);
      }

      &:last-of-type {
        border: 0;
        margin-bottom: 0;
      }

      a:hover, a:active {
        color: $white;
      }
    }

    a {
      display: inline;
      color: $white;
      font-weight: 400;

      @media #{$lg} {
        color: $grey;
        font-weight: 500;
      }

      &::before {
        content: "0" counters(section, ".") " ";
        counter-increment: section;
      }
    }
  }

  .header__dropdown {
    position: relative;

    .header__dropdown-toggle {
      position: absolute;
      top: rem(16);
      right: 0;
      width: rem(14);
      height: rem(14);
      border: unset;
      background-color: transparent;
      color: $green;
      font-size: 0;

      @media #{$lg} {
        position: static;
        color: $primary-color;
      }

      &::after {
        content: '+';
        font-size: rem(12);
      }
    }

    @media #{$lg} {
      &:hover {
        .header__dropdown-toggle {
          &::after {
            content: '-';
          }
        }
      }
    }

    &.is-open {
      .header__dropdown-toggle {
        &::after {
          content: '-';
        }
      }
    }
  }

  .header__actions {
    display: flex;
    align-items: center;

    @media #{$lg} {
      gap: rem(24);
    }

    a:not(.l-btn) {
      display: none;
      min-width: fit-content;
      font-size: rem(12);
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;

      @include transition;

      @media #{$lg} {
        display: block;
      }

      &:hover {
        @media #{$lg} {
          color: $mid-grey;
        }
      }
    }

    .l-btn {
      padding: rem(9) rem(10) rem(10) rem(11);

      @media #{$lg} {
        padding: rem(12.5) rem(16) rem(15.5);
      }

      @media #{$lg-m} {
        min-width: auto;
        border: none;

        &::after {
          content: none;
        }
      }
    }

    input {
      overflow: hidden;
      width: 0;
      height: 0;
      padding: 0;
      opacity: 0;
      visibility: hidden;

      @media #{$lg} {
        display: none;
      }

    }
  }

  .header__caption {
    margin-top: fluid(90, 96);
    color: $white;
    font-size: rem(11);
    font-weight: 400;
    letter-spacing: normal;
    line-height: 110%;
    text-transform: capitalize;

    @media #{$lg} {
      font-size: rem(12);
    }
  }

  .header__hamburger {
    display: flex;
    width: rem(31);
    height: rem(31);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: transparent;
    cursor: pointer;
    font-size: 0;
    gap: rem(2);
    line-height: 0;
    text-decoration: none;

    span {
      display: flex;
      width: rem(8);
      height: rem(1);
      background-color: $dark-grey;
      font-size: 0;

      @include transition;
    }

    @media #{$lg} {
      display: none;
    }

  }

  .header__nav {
    display: flex;
  }

  &:has(.header__actions input:checked) {
    @media #{$lg-m} {
      background-color: $dark-grey;

      .header__navlist {
        display: block;
      }
    }

    .header__navlist {
      @media #{$lg-m} {
        overflow: auto;
        background-color: $dark-grey;
        opacity: 1;
        transform: translateY(0);
      }
    }

    .header__actions {
      .l-btn {
        @include is-hidden;
      }
    }

    .header__hamburger {
      span {
        width: rem(12);

        &:nth-child(1) {
          transform: rotate(45deg) translate(rem(1.8), rem(1.8));
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(rem(2.4), rem(-2.5));
        }
      }
    }

    .header__dropdown.is-open {
      a:not(.header__subnav a) {
        &::after {
          content: '-';
        }
      }
    }

    .header__caption {
      @include is-hidden;
    }
  }
}

body {
  &:has(.header__actions input:checked) {
    overflow: hidden;
  }
}