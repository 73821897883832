@use "sass:math";

$body-size: 16;
/* stylelint-disable */
$body-font-size: 16px;
/* stylelint-enable */


@function em($value, $base: 16) {
  $new-value: calc($value / $base * 1em);

  @return $new-value;
}

@function rem($value) {
  $new-value: calc($value / $body-size * 1rem);

  @return $new-value;
}

@function px2em($px) {
  @return calc($px / $body-font-size) * 1em;
}

@mixin font-size($font-size, $line-height: false) {
  font-size: px2em($font-size);

  @if $line-height != false {
    line-height: calc($line-height / $font-size);
  }
}

@function vw-calc($px-value, $vpwidth: 1440) {
  $vw-value: calc($px-value / $vpwidth * 100vw);

  @return $vw-value;
}

// line clamp
@mixin clamp-lines($lineCount) {
  display: -webkit-box;
  overflow: hidden;
  -Webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
}

// Transition
@mixin transition($properties: all, $duration: 0.3s, $timing-function: ease-in-out) {
  transition: $properties $duration $timing-function;
}

// mixin for finding col width
@mixin col-width($card-number, $gap) {
  width: calc((100% / #{$card-number}) - ((#{$gap} * (#{$card-number} - 1)) / #{$card-number}) * rem(1));
}

@function rnd($number, $places: 0) {
  $factor: math.pow(10, $places);

  @return math.div(math.round($number * $factor), $factor);
}

/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
$default-min-bp: 376;
$default-max-bp: 1440;
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */

/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
@function fluid($min-px, $max-px, $min-bp: $default-min-bp, $max-bp: $default-max-bp) {
  $slope: math.div(($max-px - $min-px), ($max-bp - $min-bp));
  $intercept-px: rnd($min-px - ($slope * $min-bp), 5);
  $slope-vw: rnd($slope * 100, 5);

  @return clamp(rem($min-px), calc(#{$slope-vw}vw + rem($intercept-px)), rem($max-px));
}

@function vw($px-value, $viewport-width: 1440) {
  @return calc($px-value / $viewport-width * 100vw);
}

@mixin font-vw($font-size) {
  @media #{$xxxl} {
    font-size: vw($font-size);
  }
}

@mixin separator($mob-container-padding: 16, $desk-container-padding: 20, $color: $grey, $opacity: 1) {
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - (2 * rem($mob-container-padding)));
    height: 1px;
    background-color: $color;
    content: '';
    opacity: $opacity;
    transform: translateX(-50%);

    @media #{$lg} {
      width: calc(100% - (2 * rem($desk-container-padding)));
    }
  }
}

// hide and show
@mixin is-hidden() {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@mixin is-visible() {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

/* stylelint-disable */
$xs: "screen and (min-width:" + (368px) + ")";
$sm: "screen and (min-width:" + (480px) + ")";
$sml: "screen and (min-width:" + (640px) + ")";
$mds: "screen and (min-width:" + (720px) + ")"; 
$md: "screen and (min-width:" + (768px) + ")";
$lg: "screen and (min-width:" + (991px) + ")";
$lgx: "screen and (min-width:" + (1024px) + ")"; 
$xl: "screen and (min-width:" + (1199px) + ")";
$xxl: "screen and (min-width:" + (1320px) + ")";
$xxxl: "screen and (min-width:" + (1440px) + ")";
$vl: "screen and (min-width:" + (1920px) + ")";
$vvl: "screen and (min-width:" + (2520px) + ")";


$sm-m: "screen and (max-width:" + (479px) + ")";
$md-m: "screen and (max-width:" + (767px) + ")";
$lg-m: "screen and (max-width:" + (990px) + ")";
$xl-m: "screen and (max-width:" + (1198px) + ")";
/* stylelint-enable */