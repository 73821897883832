.l-checkbox {
  width: max-content;

  input {
    display: none;
    width: initial;
    height: initial;
    padding: 0;
    margin-bottom: 0;
    cursor: pointer;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    color: $grey-100;
    cursor: pointer;
    font-size: rem(12);
    font-weight: 500;
    gap: rem(8);
    line-height: 1;
    text-transform: uppercase;

    @include transition;

    &::before {
      @include transition;

      position: relative;
      display: inline-block;
      width: rem(15);
      height: rem(15);
      border: rem(1) solid $grey-100;
      -webkit-appearance: none;
      background-color: transparent;
      content: '';
      cursor: pointer;
    }
  }

  input:checked+label {
    color: $green;

    &::before {
      border: rem(4) solid $green;
    }
  }

  &:hover {
    label {
      color: $white;

      &::before {
        @media #{$xl} {
          border-color: $green;
        }
      }
    }
  }
}

label {
  color: $grey;
  font-size: rem(12);
  font-style: normal;
  line-height: 120%;
  text-transform: uppercase;
}

input, textarea {
  padding: rem(10);
  border: 0;
  border-radius: 0;
  background-color: white;
  color: $dark-grey-2;
  font-size: rem(13);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  outline: 0;
  text-transform: uppercase;
}

select {
  padding: rem(10) rem(16);
  border: 0;
  border-radius: 0;
  background-color: $dark-grey;
  color: $white;
  font-size: rem(12);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  outline: 0;
  text-transform: uppercase;
}

textarea {
  height: rem(36);
  resize: vertical;
}