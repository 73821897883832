.container {
  max-width: rem(1920);
  padding: 0 rem(16);
  margin: 0 auto;

  @media #{$lg} {
    padding: 0 rem(20);
  }

  &--lg {
    padding: 0 rem(15) 0 rem(16);

    @media #{$lg} {
      padding: 0 rem(20) 0 rem(18);
    }
  }

  &--full {
    padding: 0;
  }

  &--fluid {
    padding: 0;

    @media #{$lg} {
      padding: 0 rem(20);
    }
  }

  &--testimonial {
    @media #{$lg} {
      padding-right: rem(17);
    }
  }
}

section.section {
  &--page-header {
    padding: rem(37) 0 rem(68);

    @media #{$lg} {
      padding: rem(50) 0 rem(80);
    }
  }
}