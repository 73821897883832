.qpac.footer {
  overflow: hidden;
  padding: rem(56) 0 fluid(16, 20);
  background-color: $grey-600;

  @media #{$lg} {
    padding-top: rem(38);
  }

  .container {
    @media #{$md} {
      display: grid;
      column-gap: rem(50);
      grid-template-columns: 26.03% auto;
      grid-template-rows: auto auto auto;
    }
  }

  h3 {
    position: relative;
    padding-left: fluid(12, 18);
    margin: fluid(0, 5) 0 0;
    color: $green;
    font-size: fluid(10, 12);
    font-weight: 400;
    grid-column: 1;
    grid-row: 1;
    line-height: 120%;
    text-transform: uppercase;

    &::before {
      position: absolute;
      top: 0;
      left: fluid(0, 6);
      content: "\2022";
      font-size: fluid(8, 9);
      font-weight: 400;
    }
  }

  .footer__newsletter {
    margin-top: rem(22);
    grid-column: 2;
    grid-row: 1;

    @media #{$lg} {
      margin-top: 0;
    }

    form {
      width: 100%;
      max-width: rem(397);

      label {
        display: none;
      }

      .footer__input-wrapper {
        position: relative;
        display: grid;
        max-height: rem(33);
        grid-template-columns: auto 12.6%;
        grid-template-rows: auto auto;

        @media #{$sm} {
          grid-template-columns: auto 10.9%;
        }

        @media screen and (max-width: "374px") {
          grid-template-columns: auto 15%;
        }

        input {
          width: 100%;
          padding-bottom: rem(15);
          border: none;
          border-bottom: rem(1) solid $white;
          margin-top: rem(5);
          background-color: transparent;
          color: $white;
          font-size: rem(12);
          grid-column: 1 / -1;
          grid-row: 1 / 2;
          line-height: 120%;
          outline: none;

          &::placeholder {
            color: $white;
            text-transform: uppercase;
          }

          &:-webkit-autofill {
            background-color: transparent;
            -webkit-box-shadow: 0 0 rem(0) rem(100) $grey-600 inset;
            -webkit-text-fill-color: $white;
          }

          @media #{$sml} {
            padding: 0 rem(50) rem(14) 0;
          }
        }

        .footer__button {
          grid-column: 2 / -1;
          grid-row: 1 / 2;
        
          button {
            position: relative; 
            display: flex;
            overflow: hidden; 
            width: rem(43);
            height: rem(22);
            align-items: center;
            justify-content: center;
            border: none;
            border: rem(1) solid $green;
            border-radius: rem(4);
            background-color: $green;
            cursor: pointer;
            font-size: 0;
            line-height: 0;
            text-decoration: none;
    
            @include transition;
        
            @media #{$lg} {
              transform: translateY(rem(1));
            }
        
            svg {
              path {
                fill: $black;
        
                @include transition;
              }
            }
        
            
            &::before {
              @media #{$lg} {
                position: absolute;
                z-index: 0;
                top: 0;
                left: -100%; 
                width: 100%;
                height: 100%;
                background-color: $grey-600;
                content: '';
                transform: translateX(0); 
                transition: transform 0.4s ease; 
              }
            }
        
            &:hover {
              background-color: unset;
              
              @media #{$lg} {
                background-color: $green;
              }
        
              &::before {
                transform: translateX(100%); 
              }
        
              svg {
                path {
                  fill: $green;
                }
              }
            }
        
          
            & > * {
              position: relative;
              z-index: 1;
            }
        
            &.footer__button-submit {
              .is-submit {
                display: block;
              }
        
              .is-success {
                display: none;
              }
            }

            &.footer__button-success {
              .is-submit {
                display: none;
              }
        
              .is-success {
                display: block;
              }
            }
          }
        
          .footer__button-success {
            border: rem(1) solid $grey-400;
            background-color: $grey-400;
        
            svg {
              path {
                fill: unset;
        
                @include transition;
              }
            }
        
            &:hover {
              border: rem(1) solid $green;
        
              svg {
                path {
                  fill: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer__links {
    margin-top: fluid(58, 98);
    grid-column: 2;
    grid-row: 2;

    .footer__links-categroy {
      display: grid;
      flex-direction: column;
      gap: rem(40);
      grid-template-columns: auto;
      grid-template-rows: auto auto auto auto;

      @media #{$sm} {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        ;
      }

      @media #{$lg} {
        display: flex;
        flex-direction: row;
        gap: rem(16);
      }

      .footer__link-group {
        display: flex;
        flex-direction: row;
        gap: rem(16);

        @media #{$sm} {
          width: 100%;
          max-width: rem(220);
          flex-direction: column;
          gap: rem(6);
        }

        h4,
        a {
          color: $grey-100;
          font-size: rem(12);
          font-weight: 500;
          line-height: 166.667%;
          text-transform: uppercase;
        }

        h4 {
          width: 34.88%;
          margin: 0;
          opacity: 0.6;

          @media #{$sml} {
            width: 100%;
          }
        }

        ul {
          width: 60.46%;

          @media #{$sml} {
            width: 100%;
          }

          li {
            display: flex;
            margin-bottom: rem(8.1);

            @media #{$sml} {
              display: block;
              margin-bottom: rem(6);
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              @include transition;

              &:hover {
                @media #{$lg} {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer__legal-links {
    margin-top: rem(65);
    grid-column: 1;
    grid-row: 3;

    @media #{$md} {
      display: flex;
      align-items: flex-end;
      margin-top: 0;
    }

    .footer__legal-categroy {
      display: flex;
      gap: rem(17);

      @media #{$sml} {
        margin-bottom: rem(5);
      }

      .footer__legal-item {
        display: flex;

        a {
          color: $grey-100;
          font-size: rem(10);
          line-height: 240%;
          text-transform: uppercase;

          @include transition;

          @media #{$sml} {
            line-height: 1;
          }

          &:hover {
            @media #{$lg} {
              color: $mid-grey;
            }
          }
        }
      }
    }
  }

  .footer__bottom {
    display: flex;
    flex-direction: column;
    margin-top: fluid(37, 109, 375, 768);
    gap: fluid(38, 50);
    grid-column: 2;
    grid-row: 3;

    @media screen and (min-width: "768px") and (max-width: "1024px") {
      margin-left: auto;
    }

    @media #{$xl} {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    span {
      color: $green;
      font-size: rem(12);
      line-height: 110%;
      text-transform: capitalize;

      @media #{$sml} {
        margin-bottom: rem(5);
      }
    }

    img {
      width: 100%;
      max-width: fluid(344, 515);
      max-height: fluid(68.1, 102);

      svg {
        g {
          fill: $green;
        }
      }
    }

  }
}